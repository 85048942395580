import { useRequest } from '@/composables/useRequest';
import { ApiMethod, HeaderTypes } from '@/enums/api.enum';
import type { BannerImagesRequest } from '@/types/banner-image/banner-image-request.type';
import type {
  BannerImageResponse,
  BannerImagesResponse
} from '@/types/banner-image/banner-image-response.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+banner+image
export const fetchBannerImageListByProductNoApi = async (
  productNo: string
): Promise<BannerImageResponse[]> => {
  const { data } = await useRequest<BannerImagesResponse>(
    `studio/v1/products/${productNo}/banner-image/files`,
    {
      headers: {
        ...generateHeader(HeaderTypes.XLang, HeaderTypes.XNation)
      },
      method: ApiMethod.Get
    }
  );

  return data?.contents ?? [];
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SDKAPIDOCU&title=WDB03.+studio+%7C+banner+image#36d53f8a-89ac-459a-8343-79c31b0903b8-446681355
export const uploadBannerImagesApi = async (
  productNo: string,
  request: BannerImagesRequest
): Promise<void> => {
  await useRequest<void>(`studio/v1/products/${productNo}/banner-image/files`, {
    headers: {
      ...generateHeader(HeaderTypes.XLang, HeaderTypes.XNation)
    },
    method: ApiMethod.Post,
    data: camelToSnake(request)
  });
};
