<template>
  <div class="flex gap-24">
    <div class="flex-1">
      <st-box>
        <st-form-title
          :formTitle="$t('studio.prj_prod.this_prod.marketing_banner_rectangular_image_title')"
          class="!mb-8"
        />
        <input-product-file
          mode="cover"
          resolution="960x882"
          :isDisabled="!isProductWritable"
          class="mt-8"
          name="image2x2"
          :inList="false"
          :extensions="extensions"
          :type="FILE_TYPE.IMAGE"
          :cropBox="{
            width: 960,
            height: 882
          }"
          :stencilSize="{
            width: 400,
            height: 368
          }"
          :safeArea="{
            width: 290,
            height: 290
          }"
          :isHiddenAddLogo="true"
          :original="false"
          :disableOpacity="true"
          isZoomLimit
          isGuideShown
          expandFileNameByRightWing
          @removeFile="onRemoveFile('image2x2')"
        />
        <st-form-title
          :formTitle="$t('studio.prj_prod.this_prod.marketing_banner_portrait_image_title')"
          class="!mb-8 mt-32"
        />
        <input-product-file
          :fileNameWidth="350"
          :expandedWidth="600"
          mode="cover"
          :isDisabled="!isProductWritable"
          resolution="366x771"
          class="mt-8"
          name="image1x2"
          :inList="false"
          :extensions="extensions"
          :type="FILE_TYPE.IMAGE"
          :cropBox="{
            width: 366,
            height: 771
          }"
          :safeArea="{
            width: 138,
            height: 246
          }"
          :stencilSize="{
            width: 190,
            height: 400
          }"
          :isHiddenAddLogo="true"
          :original="false"
          :disableOpacity="true"
          isZoomLimit
          isGuideShown
          expandFileNameByRightWing
          @removeFile="onRemoveFile('image1x2')"
        />
        <st-form-title
          :formTitle="$t('studio.prj_prod.this_prod.marketing_banner_landscape_image_title')"
          class="!mb-8 mt-32"
        />
        <input-product-file
          mode="cover"
          resolution="960x426"
          class="mt-8"
          name="image2x1"
          :isDisabled="!isProductWritable"
          :inList="false"
          :extensions="extensions"
          :type="FILE_TYPE.IMAGE"
          :cropBox="{
            width: 960,
            height: 426
          }"
          :safeArea="{
            width: 428,
            height: 192
          }"
          :stencilSize="{
            width: 680,
            height: 302
          }"
          :isHiddenAddLogo="true"
          :original="false"
          :disableOpacity="true"
          isZoomLimit
          isGuideShown
          expandFileNameByRightWing
          @removeFile="onRemoveFile('image2x1')"
        />
      </st-box>
      <div class="mt-40 flex justify-center gap-16">
        <s-button variant="outline" size="lg" class="!min-w-160" @click="onPreview">
          {{ $t('studio.prj_prod.this_prod.marketing_banner_preview_btn') }}
        </s-button>
        <s-button variant="primary" size="lg" class="!min-w-160" @click="onSave">
          {{ $t('studio.common.popup_case_save_btn') }}
        </s-button>
      </div>
    </div>
    <right-wing>
      <right-wing-item :rightWingTitle="$t('studio.prj_prod.this_prod.marketing_banner_guide1')">
        <safe-html :html="$t('studio.prj_prod.this_prod.marketing_banner_guide1_1')" />
      </right-wing-item>
    </right-wing>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm } from 'vee-validate';
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import { definePageMeta, useProduct } from '@/.nuxt/imports';
import { fetchBannerImageListByProductNoApi, uploadBannerImagesApi } from '@/apis/banner-image.api';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import BannerImageDialog from '@/components/product-page/banner-image-dialog.vue';
import InputProductFile from '@/components/product-page/input-product-file.vue';
import { useApp } from '@/composables/useApp';
import { showDialog } from '@/composables/useDialog';
import { IMAGE_TYPE } from '@/constants/file.const';
import { PRODUCT_TITLE } from '@/constants/page.const';
import { ROLE_ID } from '@/constants/permission.const';
import { FILE_TYPE } from '@/enums/product-page.enum';
import useProductStore from '@/stores/product.store';
import type { ImageLanguageType } from '@/types/common/file.type';
import type { ProductSettingInfo } from '@/types/product/product-model.type';
import {
  transformBannerImageResponse,
  transformBannerImagesRequest
} from '@/utils/banner-image.util';
import { showConfirmLeaveDialog } from '@/utils/common.util';

definePageMeta({
  title: '배너 이미지',
  middleware: 'handle-product-data-middleware',
  roleAuthorityId: ROLE_ID.BANNER_IMAGE,
  pageTitle: PRODUCT_TITLE.BANNER_IMG
});

const { checkProductPermission } = useApp();

const productComposable = useProduct();
const { showCompleteSaveDialog } = productComposable;

const extensions = [IMAGE_TYPE.JPG, IMAGE_TYPE.PNG, IMAGE_TYPE.GIF, IMAGE_TYPE.WEBP];

const route = useRoute();
const { locale } = useI18n();
const productStore = useProductStore();

const { product, isProductWritable } = storeToRefs(productStore);

const productNo = computed(() => route.params.productId as string);
const productName = computed(() => {
  const infos = product.value?.infos ?? [];
  return (
    infos.find(({ languageCd }: ProductSettingInfo) => languageCd === locale.value)?.productName ??
    ''
  );
});

const formattedBannerImages = ref<Record<string, ImageLanguageType>>({});

const { handleSubmit, setValues } = useForm({
  initialValues: formattedBannerImages.value
});

const image2x2 = useFieldValue<ImageLanguageType>('image2x2');
const image1x2 = useFieldValue<ImageLanguageType>('image1x2');
const image2x1 = useFieldValue<ImageLanguageType>('image2x1');

const onPreview = async () => {
  await showDialog({
    component: shallowRef(BannerImageDialog),
    props: {
      productName: productName.value,
      image2x2: image2x2.value.linkCdn,
      image1x2: image1x2.value.linkCdn,
      image2x1: image2x1.value.linkCdn
    }
  });
};

const onSave = handleSubmit(async (data: Record<string, ImageLanguageType>) => {
  if (!(await checkProductPermission())) {
    return;
  }
  await uploadBannerImagesApi(productNo.value, transformBannerImagesRequest(data));
  showCompleteSaveDialog();
});

const onRemoveFile = (name: string) => {
  formattedBannerImages.value[name] = {
    image: '',
    fileId: '',
    lang: 'ko',
    linkCdn: '',
    name: ''
  };
};

const init = async () => {
  const result = await fetchBannerImageListByProductNoApi(productNo.value);
  if (result) {
    formattedBannerImages.value = Object.fromEntries(result.map(transformBannerImageResponse));
    setValues(formattedBannerImages.value);
  }
};

init();

onBeforeRouteLeave(async () => {
  return await showConfirmLeaveDialog();
});
</script>
