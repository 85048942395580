import { ARRAY_FILE_TYPE } from '@/constants/file.const';
import type { BannerImagesRequest } from '@/types/banner-image/banner-image-request.type';
import type { BannerImageResponse } from '@/types/banner-image/banner-image-response.type';
import type { ImageLanguageType } from '@/types/common/file.type';

const transformRatioToImage = (name: string): string => {
  return name.replace(/^RATIO_/, 'image');
};

const transformImageToRatio = (name: string): string => {
  return name.replace(/^image/, 'ratio_').toUpperCase();
};

export const transformBannerImagesRequest = (
  data: Record<string, ImageLanguageType>
): BannerImagesRequest => {
  const files = Object.keys(data).map((key: string) => {
    const item: ImageLanguageType = data[key];
    return {
      fileId: (item.fileId as string) ?? '',
      linkCdn: item.linkCdn ?? '',
      fileName: item.name ?? '',
      ratio: transformImageToRatio(key),
      fileType: (ARRAY_FILE_TYPE[item?.fileTypeRaw ?? ''] ?? item?.fileTypeRaw) ?? item?.fileType
    };
  });
  return { files };
};

export const transformBannerImageResponse = (
  bannerImage: BannerImageResponse
): [string, ImageLanguageType] => {
  const newName = transformRatioToImage(bannerImage.ratio).toLowerCase();
  return [
    newName,
    {
      fileId: bannerImage.fileId,
      lang: 'ko',
      image: bannerImage.linkCdn,
      linkCdn: bannerImage.linkCdn,
      name: bannerImage.fileName,
      fileType: bannerImage.fileType
    }
  ];
};
